// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { Bulletin } from "modules";
import { ImageType } from "components/post/WriteTemplate";
import moment from "moment";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

type BulletinResultType = Bulletin[] | undefined;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const storage = getStorage(app);
const noticePath = (fileName: string) => {
  return "notice/" + fileName + ".jpg";
};

// Create a storage reference from our storage service
// const firebaseStorageRef = ref(storage);

const firebaseAuth = getAuth();

const firebaseAuthorize = async () => {
  if (firebaseAuth.currentUser == null) {
    return await signInAnonymously(firebaseAuth);
  }
  return true;
};

async function getListOfFirebaseStorage(
  path: string
): Promise<BulletinResultType> {
  const response = listAll(ref(storage, "bulletins"))
    .then((res) => {
      // res.prefixes.forEach((folderRef) => {
      //   // All the prefixes under listRef.
      //   // You may call listAll() recursively on them.
      //   console.log(folderRef);
      // });
      const bulletins: Bulletin[] = [];
      if (res.items.length === 0) {
        return bulletins;
      }

      const items = res.items;
      const fileNames = items.map((itemRef) => itemRef.name);
      const data = Promise.all(
        items.map((itemRef) => getDownloadURL(itemRef))
      ).then((urls) => {
        urls.forEach((url, index) => {
          let date = fileNames[index].split(".")[0];
          date =
            date.slice(0, 4) +
            "년 " +
            Number(date.slice(4, 6)) +
            "월 " +
            Number(date.slice(6, 8)) +
            "일";
          bulletins.push({
            id: index,
            date: date ?? "",
            thumbnailUrl: "",
            url: url,
          });
        });
        bulletins.sort((a, b) => b.date.localeCompare(a.date));
        return bulletins;
      });
      return data;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error.code);
      if (error.code === "storage/unauthorized") {
        // authorize();
      }
      return Promise.reject(error);
    });

  return response;
}

export async function getBulletinFileList(): Promise<BulletinResultType> {
  const authorize = await firebaseAuthorize().then(() => {
    return true;
  });

  if (authorize) {
    return await getListOfFirebaseStorage("bulletins");
  }
}

export async function uploadPostImage(
  dir: string,
  imgs: ImageType[]
): Promise<string[]> {
  const authorize = await firebaseAuthorize().then(() => {
    return true;
  });

  if (authorize) {
    let id = "admin"; //todo: replaced with userId
    let folder = moment().format("YYYYMMDD_HHmmss") + "_" + dir + "/";
    const imagePromises: Promise<string>[] = imgs.map(async (img, i) => {
      let fileName = `${folder}${id}_${i}`;
      console.log(fileName);
      return await uploadImage(fileName, img.file);
    });
    return await Promise.all(
      imagePromises.map((promise) =>
        promise
          .then((fileUrl) => {
            console.log("fileURL ", fileUrl);
            if (fileUrl === undefined) {
              return Promise.reject("Error");
            }
            return Promise.resolve(fileUrl);
          })
          .catch((error) => {
            console.log(error);
            return Promise.reject(error);
          })
      )
    );
  }
  return [];
}

const uploadImage = async (fileName: string, file: File): Promise<string> => {
  const fileRef = ref(storage, noticePath(fileName));
  const uploadTask = uploadBytesResumable(fileRef, file);
  console.log("UPLOAD");
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
        console.log(error);
        reject(error);
      },
      async () => {
        // Upload completed successfully, now we can get the download URL
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("File available at", downloadURL);
        resolve(downloadURL);
      }
    );
  });
};
